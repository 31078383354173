<template>
  <in-layout>
    <template slot="list">
      <v-container grid-list-md class="px-4">

        <v-layout column class="ma-2">
          <div class="mb-4">
            <router-link style="cursor: pointer" tag="span" :to="{ name: 'Usuários'}">
              <v-icon size="18">mdi-chevron-left</v-icon>
              Voltar para usuários cadastrados
            </router-link>
          </div>

          <h1>Dados do usuário</h1>
        </v-layout>

        <v-flex class="mx-2 my-4" xs12>
          <v-card>
            <v-card-text class="pa-3">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field
                      label="Nome"
                      v-model="entity.name"
                      :rules="[validationRules.required]"
                      required
                    />

                    <v-layout row>
                      <v-flex xs6>
                        <v-text-field
                          v-model="entity.cpf"
                          label="CPF"
                          :mask="getCpfCnpjMask(entity.cpf)"
                          disabled
                        />
                      </v-flex>

                      <v-flex xs6>
                        <v-text-field
                          v-model="entity.email"
                          label="Email"
                          :rules="[validationRules.required, validationRules.email]"
                          required
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-spacer />
                </v-layout>
              </v-form>

            </v-card-text>
            <v-card-actions class="pa-3">
              <v-btn color="primary" :disabled="!valid" @click="save">Salvar alterações</v-btn>
              <v-btn flat @click="cancel">Cancelar</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-divider/>

        <people-user-list v-if="entity.people_users" :peoples="entity.people_users" :user="entity" @listPeopleUpdated="getPeoples" />

      </v-container>
    </template>
  </in-layout>
</template>

<script>
import InLayout from '@/layouts/InLayout.vue';
import UserService from '@/services/user-service';
import validationRules from '@/mixins/validation-rules';
import masks from '@/utils/masks';
import { mapActions } from 'vuex';
import { cloneDeep } from 'lodash';
import PeopleUserList from '../../components/user/PeopleUserList.vue';

export default {
  name: 'UserData',
  components: {
    InLayout,
    PeopleUserList
},
  mounted() {
    this.getUserData();
  },
  data() {
    return {
      id: this.$route.params.id,
      masks,
      validationRules,
      entity: {},
      initialEntity: {},
      valid: false,
    };
  },
  methods: {
    ...mapActions({ toggleSnackbar: 'application/toggleSnackbar' }),
    getUserData() {
      UserService
        .show(this.id)
        .then(async (data) => {
          this.initialEntity = {
            ...data,
          };
          this.cloneEntity();
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.message,
            messageIcon: 'mdi-alert-outline',
          });
        })
    },
    cloneEntity() {
      this.entity = cloneDeep(this.initialEntity);
    },
    save() {
      const payload = {
        ...this.entity,
      };

      UserService
        .updateUser(this.id, payload)
        .then(() => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'success',
            message: 'Dados atualizados com sucesso!',
            messageIcon: 'mdi-check',
          });
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.response.data.join(', '),
            messageIcon: 'mdi-alert-outline',
          });
        })
    },
    cancel() {
      this.cloneEntity();
    },
    getCpfCnpjMask(cpfCnpj = '') {
      return cpfCnpj.length < 12 ? '###.###.###-###' : '##.###.###/####-##';
    },
    getPeoples() {
      this.getUserData();
    },
  },
};
</script>

<style>
</style>
