var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { staticClass: "ma-2", attrs: { column: "" } },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            "align-items": "center",
          },
        },
        [
          _c("span", { staticClass: "subheading font-weight-bold" }, [
            _vm._v(_vm._s(_vm.quantityOfPeople)),
          ]),
          _c(
            "v-btn",
            {
              staticClass: "green darken-1 white--text",
              attrs: { flat: "" },
              on: { click: _vm.addPeople },
            },
            [_vm._v("Adicionar")]
          ),
        ],
        1
      ),
      _vm._l(_vm.peoples, function (people) {
        return _c(
          "v-card",
          { key: people.id, staticClass: "mt-3", attrs: { elevation: "2" } },
          [
            _c(
              "v-card-title",
              { staticClass: "px-3 py-2" },
              [
                _c("v-icon", [_vm._v("mdi-account")]),
                _c("span", { staticClass: "ml-1" }, [
                  _vm._v(_vm._s(people.person.name)),
                ]),
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    staticClass: "text-capitalize",
                    attrs: { flat: "", small: "", color: "red" },
                    on: {
                      click: function ($event) {
                        return _vm.openConfirmUnlinkDialog(people)
                      },
                    },
                  },
                  [
                    _vm._v("\n          Desvincular\n          "),
                    _c(
                      "v-icon",
                      { staticClass: "ml-2", attrs: { size: "16" } },
                      [_vm._v("mdi-cancel")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-card-text",
              { staticClass: "grey lighten-3 py-0" },
              [
                _c(
                  "v-layout",
                  { attrs: { row: "", "align-center": "" } },
                  [
                    _c("span", [
                      _vm._v("CPF/CNPJ: "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.formatCpfCnpj(people.person.cpf_cnpj))
                        ),
                      ]),
                    ]),
                    _c("span", { staticClass: "ml-3" }, [
                      _vm._v("Celular: "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm._f("phone")(people.person.cellphone))
                        ),
                      ]),
                    ]),
                    _c("span", { staticClass: "ml-3" }, [
                      _vm._v("Permissões: "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.translateRoles(people.roles))),
                      ]),
                    ]),
                    _c("v-spacer"),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "290" },
          model: {
            value: _vm.confirmUnlinkDialog,
            callback: function ($$v) {
              _vm.confirmUnlinkDialog = $$v
            },
            expression: "confirmUnlinkDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Atenção"),
              ]),
              _c("v-card-text", [
                _vm._v(
                  "Você tem certeza que deseja desvincular essa empresa desse usuário?"
                ),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1 white--text", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.confirmUnlinkDialog = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1 white--text", text: "" },
                      on: { click: _vm.confirmUnlink },
                    },
                    [_vm._v("Confirmar")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500", persistent: "" },
          model: {
            value: _vm.linkToPersonDialog,
            callback: function ($$v) {
              _vm.linkToPersonDialog = $$v
            },
            expression: "linkToPersonDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline green darken-2 white--text",
                  attrs: { width: "700", "primary-title": "" },
                },
                [_vm._v("\n        Vincular pessoa Física ou Jurídica\n      ")]
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-stepper",
                    {
                      staticClass: "custom-shadow",
                      model: {
                        value: _vm.e1,
                        callback: function ($$v) {
                          _vm.e1 = $$v
                        },
                        expression: "e1",
                      },
                    },
                    [
                      _c(
                        "v-stepper-header",
                        [
                          _c("v-stepper-step", {
                            attrs: { complete: _vm.e1 > 1, step: "1" },
                          }),
                          _c("v-divider"),
                          _c("v-stepper-step", {
                            attrs: { complete: _vm.e1 > 2, step: "2" },
                          }),
                          _c("v-divider"),
                          _c("v-stepper-step", {
                            attrs: { complete: _vm.e1 > 3, step: "3" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-items",
                        [
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "1" } },
                            [
                              _vm._v(
                                "\n                Para poder vincular uma nova Pessoa Física ou Jurídica a esse\n                usuário, você deverá informar um CPF ou CNPJ já cadastrado no\n                sistema na próxima etapa.\n              "
                              ),
                              _c(
                                "v-layout",
                                { attrs: { "justify-center": "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { flat: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.cancelLinkPerson()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Cancelar\n                "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.e1 = 2
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Prosseguir\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "2" } },
                            [
                              !_vm.noPersonToSelect
                                ? _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        "align-center": "",
                                        "justify-center": "",
                                        column: "",
                                        "fill-height": "",
                                      },
                                    },
                                    [
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _vm._v(
                                          "\n                  Insira o CPF ou CNPJ abaixo da Empresa ou Pessoa Física que deseja\n                  realizar a vinculação.\n                  "
                                        ),
                                      ]),
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            "align-center": "",
                                            "justify-center": "",
                                            row: "",
                                            "fill-height": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "mr-3 mt-3",
                                              attrs: { xs6: "" },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "CPF ou CNPJ",
                                                  mask: _vm.getMask(
                                                    _vm.cpfCnpj
                                                  ),
                                                },
                                                model: {
                                                  value: _vm.cpfCnpj,
                                                  callback: function ($$v) {
                                                    _vm.cpfCnpj = $$v
                                                  },
                                                  expression: "cpfCnpj",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-layout",
                                            { attrs: { "justify-center": "" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { small: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.cancelLinkPerson()
                                                    },
                                                  },
                                                },
                                                [_vm._v("Cancelar")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.getPerson()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-magnify"),
                                                  ]),
                                                  _vm._v(
                                                    "\n                      Buscar\n                    "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    _vm._l(
                                      _vm.peoplesToLink,
                                      function (person) {
                                        return _c(
                                          "div",
                                          {
                                            key: person.id,
                                            attrs: { xs12: "" },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(person.name) +
                                                  " - " +
                                                  _vm._s(person.cpf_cnpj)
                                              ),
                                            ]),
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  flat: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.nextStepThree(
                                                                        person
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-check"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Vincular Pessoa Física ou Jurídica"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                              _vm.noPersonToSelect
                                ? _c(
                                    "v-layout",
                                    [
                                      _c("v-flex", [
                                        _c("div", { attrs: { xs12: "" } }, [
                                          _vm._v(
                                            "\n                    Não existe uma Pessoa Física ou Jurídica cadastrada\n                    com o CPF/CNPJ informado. Verifique se digitou corretamente\n                    ou se esse CPF/CNPJ já foi cadastrado.\n                  "
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.noPersonToSelect
                                ? _c(
                                    "v-layout",
                                    { attrs: { "justify-center": "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { flat: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.cancelLinkPerson()
                                            },
                                          },
                                        },
                                        [_vm._v("Cancelar")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              _vm.noPersonToSelect = false
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Tentar novamente\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "3" } },
                            [
                              _c("div", { attrs: { xs12: "" } }, [
                                _vm._v(
                                  "\n                    Selecione o perfil do usuário:\n                  "
                                ),
                              ]),
                              _c("v-select", {
                                attrs: {
                                  items: _vm.rolesArray,
                                  "item-text": "name",
                                  "item-value": "key",
                                  multiple: "",
                                  required: "",
                                },
                                model: {
                                  value: _vm.selectedRole,
                                  callback: function ($$v) {
                                    _vm.selectedRole = $$v
                                  },
                                  expression: "selectedRole",
                                },
                              }),
                              _c(
                                "v-layout",
                                { attrs: { "justify-center": "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { flat: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.cancelLinkPerson()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Cancelar\n                  "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        disabled: _vm.selectedRole.length === 0,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.linkPerson(
                                            _vm.peopleToLink
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Vincular\n                  "
                                      ),
                                      _c("v-icon", [_vm._v("mdi-check")]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }