<template>
  <v-layout column class="ma-2">
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <span class="subheading font-weight-bold">{{ quantityOfPeople }}</span>
      <v-btn flat class="green darken-1 white--text" @click="addPeople">Adicionar</v-btn>
    </div>

    <v-card class="mt-3" elevation="2" v-for="people in peoples" :key="people.id">
      <v-card-title class="px-3 py-2">
        <v-icon>mdi-account</v-icon>
        <span class="ml-1">{{people.person.name}}</span>

        <v-spacer/>

        <v-btn flat small color="red" class="text-capitalize" @click="openConfirmUnlinkDialog(people)">
            Desvincular
            <v-icon size="16" class="ml-2">mdi-cancel</v-icon>
          </v-btn>
      </v-card-title>

      <v-card-text class="grey lighten-3 py-0">
        <v-layout row align-center>
          <span>CPF/CNPJ: <strong>{{formatCpfCnpj(people.person.cpf_cnpj)}}</strong></span>
          <span class="ml-3">Celular: <strong>{{people.person.cellphone | phone}}</strong></span>
          <span class="ml-3">Permissões: <strong>{{translateRoles(people.roles)}}</strong></span>

          <v-spacer />
        </v-layout>
      </v-card-text>
    </v-card>

    <v-dialog v-model="confirmUnlinkDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Atenção</v-card-title>
        <v-card-text>Você tem certeza que deseja desvincular essa empresa desse usuário?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1 white--text" text @click="confirmUnlinkDialog = false">Cancelar</v-btn>
          <v-btn color="red darken-1 white--text" text @click="confirmUnlink">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="linkToPersonDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline green darken-2 white--text"
          width="700"
          primary-title
        >
          Vincular pessoa Física ou Jurídica
        </v-card-title>

        <v-card-text>

          <v-stepper v-model="e1" class="custom-shadow">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1"></v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 2" step="2"></v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 3" step="3"></v-stepper-step>

            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                  Para poder vincular uma nova Pessoa Física ou Jurídica a esse
                  usuário, você deverá informar um CPF ou CNPJ já cadastrado no
                  sistema na próxima etapa.
                <v-layout justify-center>
                  <v-btn flat
                         @click="cancelLinkPerson()"
                  >
                    Cancelar
                  </v-btn>

                  <v-btn
                    color="primary"
                    @click="e1 = 2">
                    Prosseguir
                  </v-btn>
                </v-layout>

              </v-stepper-content>

              <v-stepper-content step="2">

                <v-layout align-center justify-center column fill-height
                          v-if="!noPersonToSelect">
                  <v-flex xs12>
                    Insira o CPF ou CNPJ abaixo da Empresa ou Pessoa Física que deseja
                    realizar a vinculação.
                    </v-flex>
                  <v-layout  align-center justify-center row fill-height>
                    <v-flex xs6 class="mr-3 mt-3">
                      <v-text-field
                        v-model="cpfCnpj"
                        label="CPF ou CNPJ"
                        :mask="getMask(cpfCnpj)"
                      />
                    </v-flex>

                    <v-layout justify-center>
                      <v-btn small @click="cancelLinkPerson()">Cancelar</v-btn>
                      <v-btn
                        color="primary"
                        small
                        @click="getPerson()">
                        <v-icon>mdi-magnify</v-icon>
                        Buscar
                      </v-btn>
                    </v-layout>
                  </v-layout>

                </v-layout>

                <v-layout>
                  <v-flex>
                    <div xs12 v-for="person in peoplesToLink" :key="person.id">
                      <span>{{person.name}} - {{person.cpf_cnpj}}</span>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon flat
                                 v-on="on"
                                 @click="nextStepThree(person)">
                            <v-icon>mdi-check</v-icon>
                          </v-btn>
                        </template>
                        <span>Vincular Pessoa Física ou Jurídica</span>
                      </v-tooltip>
                    </div>
                  </v-flex>
                </v-layout>

                <v-layout v-if="noPersonToSelect">
                  <v-flex>
                    <div xs12>
                      Não existe uma Pessoa Física ou Jurídica cadastrada
                      com o CPF/CNPJ informado. Verifique se digitou corretamente
                      ou se esse CPF/CNPJ já foi cadastrado.
                    </div>
                  </v-flex>
                </v-layout>

                <v-layout justify-center v-if="noPersonToSelect">
                  <v-btn flat @click="cancelLinkPerson()">Cancelar</v-btn>

                  <v-btn
                    color="primary"
                    @click="noPersonToSelect = false"
                  >
                    Tentar novamente
                  </v-btn>
                </v-layout>

              </v-stepper-content>

              <v-stepper-content step="3">
                <div xs12>
                      Selecione o perfil do usuário:
                    </div>
                <v-select
                  v-model="selectedRole"
                  :items="rolesArray"
                  item-text="name"
                  item-value="key"
                  multiple
                  required
                ></v-select>

                <v-layout justify-center>
                  <v-btn flat
                          @click="cancelLinkPerson()"
                    >
                      Cancelar
                    </v-btn>

                  <v-btn color="primary" @click="linkPerson(peopleToLink)"
                  :disabled="selectedRole.length === 0">
                    Vincular
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </v-layout>

              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>

        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>

</template>

<script>

import formatCpfCnpj from '@/utils/formatCpfCnpj';
import formatters from '@/utils/formatters';
import personService from '@/services/person-service';
import employeeService from '@/services/employee-service';

export default {
  name: 'peopleList',
  props: {
    peoples: {
      required: true,
      type: Array,
    },
    user: {
      required: true,
      type: Object,
    },
  },
  mixins: [formatters],
  data() {
    return {
      formatCpfCnpj,
      linkToPersonDialog: false,
      e1: 0,
      noPersonToSelect: false,
      peoplesToLink: [],
      peopleToLink: null,
      peopleToUnlink: null,
      entityFormValid: false,
      cpfCnpj: '',
      roles: {
        admin: 'Administrador',
        company: 'Empresa',
        technical: 'Responsável Técnico',
        receiver: 'Responsável pelo Recebimento',
      },
      selectedRole: [],
      confirmUnlinkDialog: false,
    };
  },
  computed: {
    quantityOfPeople() {
      return this.peoples.length !== 1 ? `${this.peoples.length} Empresas vinculadas` : `${this.peoples.length} Empresa vinculada`;
    },
    rolesArray() {
      return Object.keys(this.roles).map(key => ({ key, name: this.roles[key] }));
    },
  },
  watch: {
    cpfCnpj(value) {
      this.cpfCnpj = value.replace(/\D/g, '');
    }
  },
  methods: {
    addPeople() {
      this.linkToPersonDialog = true;
    },
    cancelLinkPerson() {
      this.e1 = 1;
      this.linkToPersonDialog = false;
      this.noPersonToSelect = false;
    },
    async getPerson() {
      const response = await personService.searchForLink({ q: this.cpfCnpj });
      if (response.data.length > 0) {
        this.peoplesToLink = response.data;
      } else {
        this.noPersonToSelect = true;
      }
    },
    linkPerson(person) {
      const payload = {
        cpf: this.user.cpf,
        role: this.selectedRole,
        person_id: person.id,
      };
      employeeService
        .linkEmployee(payload)
        .then(() => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'success',
            message: 'Pessoa vinculada com sucesso',
            messageIcon: 'mdi-check',
          });
          this.$emit('listPeopleUpdated');
          this.linkToPersonDialog = false;
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.response.data.join(', '),
            messageIcon: 'mdi-ckeck',
          });
        });
    },
    translateRoles(userRoles) {
      return userRoles.map(role => this.roles[role]).join(', ');
    },
    nextStepThree(person) {
      this.e1 = 3;
      this.peopleToLink = person;
    },
    unlinkEmployee(personId) {
      employeeService.unlinkEmployee(this.user.id, personId)
        .then(() => {
          this.$store.dispatch('person/fetchEmployees', personId);
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'success',
            message: 'Funcionário desvinculado com sucesso',
            messageIcon: 'mdi-check',
          });
          this.$emit('listPeopleUpdated');
        })
        .catch(({ response }) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: response.data[0],
            messageIcon: 'mdi-alert-outline',
          });
        });
    },
    openConfirmUnlinkDialog(people) {
      this.peopleToUnlink = people.person;
      this.confirmUnlinkDialog = true;
    },
    confirmUnlink() {
      this.confirmUnlinkDialog = false;
      this.unlinkEmployee(this.peopleToUnlink.id);
    },
    getMask(cpfCnpj) {
      if (!cpfCnpj) return '';
      const numericValue = cpfCnpj.replace(/\D/g, '');
      return numericValue.length < 12 ? '###.###.###-###' : '##.###.###/####-##';
    },
  },
};
</script>

<style scoped>
  .custom-shadow {
    box-shadow: none;
  }
</style>
