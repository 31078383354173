var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "in-layout",
    [
      _c(
        "template",
        { slot: "list" },
        [
          _c(
            "v-container",
            { staticClass: "px-4", attrs: { "grid-list-md": "" } },
            [
              _c("v-layout", { staticClass: "ma-2", attrs: { column: "" } }, [
                _c(
                  "div",
                  { staticClass: "mb-4" },
                  [
                    _c(
                      "router-link",
                      {
                        staticStyle: { cursor: "pointer" },
                        attrs: { tag: "span", to: { name: "Usuários" } },
                      },
                      [
                        _c("v-icon", { attrs: { size: "18" } }, [
                          _vm._v("mdi-chevron-left"),
                        ]),
                        _vm._v(
                          "\n            Voltar para usuários cadastrados\n          "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("h1", [_vm._v("Dados do usuário")]),
              ]),
              _c(
                "v-flex",
                { staticClass: "mx-2 my-4", attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "pa-3" },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: { "lazy-validation": "" },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Nome",
                                          rules: [_vm.validationRules.required],
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.entity.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.entity, "name", $$v)
                                          },
                                          expression: "entity.name",
                                        },
                                      }),
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs6: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "CPF",
                                                  mask: _vm.getCpfCnpjMask(
                                                    _vm.entity.cpf
                                                  ),
                                                  disabled: "",
                                                },
                                                model: {
                                                  value: _vm.entity.cpf,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.entity,
                                                      "cpf",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "entity.cpf",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs6: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Email",
                                                  rules: [
                                                    _vm.validationRules
                                                      .required,
                                                    _vm.validationRules.email,
                                                  ],
                                                  required: "",
                                                },
                                                model: {
                                                  value: _vm.entity.email,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.entity,
                                                      "email",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "entity.email",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        { staticClass: "pa-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", disabled: !_vm.valid },
                              on: { click: _vm.save },
                            },
                            [_vm._v("Salvar alterações")]
                          ),
                          _c(
                            "v-btn",
                            { attrs: { flat: "" }, on: { click: _vm.cancel } },
                            [_vm._v("Cancelar")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _vm.entity.people_users
                ? _c("people-user-list", {
                    attrs: {
                      peoples: _vm.entity.people_users,
                      user: _vm.entity,
                    },
                    on: { listPeopleUpdated: _vm.getPeoples },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }